import React, { createContext, useContext, useState } from 'react'
import Dialog from '../components/globals/Dialog'

export type DialogType = 'message' | 'confirm' | 'delete'

export type DialogProps = {
  message?: string
  alert?: string
}

interface IDialogContext {
  showDialog: (
    dialogType: DialogType,
    dialogProps?: DialogProps,
  ) => Promise<boolean>
}

const DialogContext = createContext<IDialogContext | null>(null)

export const useDialog = () => {
  const context = useContext(DialogContext)
  if (context === null)
    throw new Error('useContext deve estar dentro do Provider.')

  return context
}

export const DialogProvider = ({ children }: React.PropsWithChildren) => {
  const [visible, setVisible] = useState(false)
  const [dialogType, setDialogType] = useState<DialogType>('message')
  const [dialogProps, setDialogProps] = useState<DialogProps>()
  const [dialogPromise, setDialogPromise] = useState<
    ((value: boolean | PromiseLike<boolean>) => void) | null
  >(null)

  const showDialog = async (
    dialogType: DialogType,
    dialogProps?: DialogProps,
  ): Promise<boolean> => {
    setDialogProps(dialogProps)
    setVisible(true)
    setDialogType(dialogType)

    return new Promise((resolve) => {
      setDialogPromise(() => resolve)
    })
  }

  const handleDialogClose = (response: boolean) => {
    setVisible(false)
    if (dialogPromise) {
      dialogPromise(response)
      setDialogPromise(null)
    }
  }

  const renderComponent = () => {
    return (
      <Dialog
        visible={visible}
        dialogType={dialogType}
        dialogProps={dialogProps}
        onClose={handleDialogClose}
      />
    )
  }

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {renderComponent()}
      {children}
    </DialogContext.Provider>
  )
}
