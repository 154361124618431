import React, { createContext, useMemo, useState } from 'react'
import { CompanyDataLogin } from '../types/Company.Types'
import * as AuthService from '../services/Auth.Service'
import { CompanyParams } from '../types/Company.Param.Types'

interface ICompanyContextType {
  company: CompanyDataLogin | null
  setCompany: React.Dispatch<React.SetStateAction<CompanyDataLogin | null>>
  companies: CompanyDataLogin[] | null
  setCompanies: React.Dispatch<React.SetStateAction<CompanyDataLogin[] | null>>
  getCompanies: (tokenUser?: string | null) => Promise<void | null>
  loadCompany: (id?: string | null) => Promise<CompanyDataLogin | null>
  companyParams: CompanyParams | null
  setCompanyParams: React.Dispatch<React.SetStateAction<CompanyParams | null>>
}

export const AuthContextCompany = createContext<ICompanyContextType | null>(
  null,
)

export const useCompany = () => {
  const context = React.useContext(AuthContextCompany)
  if (!context)
    throw new Error('useCompany precisa estar em UserContextProvider')
  return context
}

const CompanyProvider = ({ children }: { children: JSX.Element }) => {
  const [company, setCompany] = useState<CompanyDataLogin | null>(() => {
    const savedCompany = localStorage.getItem('company')
    return savedCompany ? JSON.parse(savedCompany) : null
  })
  const [companies, setCompanies] = useState<CompanyDataLogin[] | null>(null)
  const [companyParams, setCompanyParams] = useState<CompanyParams | null>(null)

  const value = useMemo(
    () => ({ company, setCompany, companies, setCompanies }),
    [company, companies],
  )

  const getCompanies = async (tokenUser?: string | null) => {
    if (!tokenUser) return null
    const { data } = await AuthService.GetCompanies(tokenUser)
    setCompanies(data)

    if (!company || !localStorage.getItem('company')) {
      setCompany(data && data?.[0])
      localStorage.setItem('company', JSON.stringify(data && data?.[0]))
    }
  }

  const loadCompany = async (id?: string | null) => {
    if (!id) return null
    const { data } = await AuthService.GetCompany(id)
    if (data) {
      setCompany(data)
      localStorage.setItem(
        'company',
        JSON.stringify({
          id: data.id,
          fantasy: data.fantasy,
          status: data.status,
          cnpjCpf: data.cnpjCpf,
        }),
      )
    }

    return data
  }

  return (
    <AuthContextCompany.Provider
      value={{
        ...value,
        getCompanies,
        loadCompany,
        companyParams,
        setCompanyParams,
      }}
    >
      {children}
    </AuthContextCompany.Provider>
  )
}

export default CompanyProvider
